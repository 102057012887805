.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.div-load-banner{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1155;
  width: 100vw;
  height: 100vh;
  background: rgba(255,255,255,0.5);
}

.div-load-banner .wrapper{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 44px;
  height: 44px;
  z-index: 10;
  background: rgba(255,255,255, 0.9);
  border-radius: 50%;
  padding: 6px 2px 2px 6px;
}

.div-load-banner .wrapper .spinner-border {
  border: 1px #fe0000 solid;
  border-right: 2px solid #065985;
}
